@font-face {
  font-family: "Toyota Type";
  src: url("./css/fonts/ToyotaType-Regular.ttf") format("truetype");
}

#root {
  min-height: 100vh;
  display: flex;
  font-family: "Toyota Type";
}

.row {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none !important;
}

.pro-sidebar {
  height: auto !important;
  text-align: right !important;
}

.pro-sidebar.md {
  min-width: 250px;
  width: 250px;
}

.pro-sidebar.collapsed {
  width: 0px !important;
  min-width: 0px !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  background-color: white;
  color: black;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
  background-color: white;
}

.card {
  transition: box-shadow 0.3s ease-in-out !important;
}

.card:hover {
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.35) !important;
}

.shadowLG {
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.35) !important;
}

.badge {
  transition: box-shadow 0.1s ease-in-out !important;
}

.badge:hover {
  box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3) !important;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__input-container {
  display: none !important;
}

/* .btn:focus {
  box-shadow: none !important;
} */

.input-error {
  border-color: #fc8181 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
}

.errorMessage {
  color: #fc4444;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

button:disabled {
  background-color: #e2e8f0 !important;
  color: black !important;
  cursor: not-allowed !important;
  opacity: 0.35 !important;
}

.footerCustomStyleTop {
  min-height: 20rem;
  background-color: darkgray;
  color: white;
}

.footerCustomStyleBottom {
  background-color: #212121;
  color: white;
}

/* .rowColorOpacity {
  background-color: rgba(131, 131, 131, 0.144);
} */

.badgeStyle {
  /* background-color: rgb(196, 195, 195); */
  font-size: 15px !important;
  color: black !important;
}

.modal-large .modal-dialog {
  max-width: 90% !important;
  height: auto;
}

.modal-large .modal-content {
  height: auto;
}

.page-link {
  color: black !important;
}

.page-item.active .page-link {
  background-color: red !important;
  color: white !important;
  border: red;
  border-bottom: red 2px solid;
  border-color: red !important;
}

/* custums arrows para el carousel empieza*/

.owl-carousel .nav-btn {
  height: 27px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 200px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  background-color: black;
  left: 0px;
  border-radius: 15px;
  color: white !important;
}
.owl-carousel .next-slide {
  background-color: black;
  border-radius: 15px;
  right: 0px;
  color: white !important;
}
.owl-carousel .prev-slide:hover {
  background-position: 0px -53px;
}
.owl-carousel .next-slide:hover {
  background-position: -24px -53px;
}

.owl-carousel {
  z-index: 0 !important;
}

/* custums arrows para el carousel termina*/

@media (max-width: 530px) {
  .carouselHeight {
    height: 40vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carouselItemHeight {
    height: 40vh !important;
    object-fit: cover;
  }
  .smNoPadding {
    padding: 0px !important;
  }
}

@media (max-width: 768px) {
  .pro-sidebar.md {
    height: 100vh !important;
  }
}

@media (max-width: 960px) {
  .modal-large .modal-dialog {
    margin-left: 5%;
  }
}

.circle {
  height: 25px;
  width: 25px;
  line-height: 0px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  left: 10px;
  top: 10px;
  -webkit-transition: height 0.25s ease, width 0.25s ease;
  transition: height 0.25s ease, width 0.25s ease;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.circle i {
  margin-left: 17px;
  color: #4badf7;
  font-size: 20px;
}

.circle:before,
.circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #fff;
}

.circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.shake:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(0.5px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-0.5px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(0.5px, 0, 0);
  }
}
